<template>
    <div ref="container">
        <v-card :loading="loading" height="100vh">
            <v-card-title ref="title">
                <v-btn
                    small
                    icon
                    @click="close"
                    color="primary"
                    class="ml-n3 mr-2"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h2 class="font-weight-regular">
                    {{ title }} /
                    <span class="font-weight-black text-uppercase">
                        PROJECT: {{ project.name }}</span
                    >
                </h2>
                <v-btn
                    v-if="invoice.rejectionReasons"
                    small
                    icon
                    @click="rejectionTable = true"
                    color="red"
                    class="ml-2"
                >
                    <v-icon>
                        mdi-book-cancel-outline
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text
                id="virtual-scroll-table"
                class="px-4"
                :style="{ height: `${this.height}px` }"
            >
                <v-form v-model="valid">
                    <v-row ref="details_1">
                        <v-col cols="12" xl="4" lg="4" md="4">
                            <v-select
                                v-model="invoice.concept"
                                :items="concepts"
                                item-text="name"
                                item-value="id"
                                outline
                                prefix="Concept: "
                                hide-details
                                prepend-icon="mdi-table-column"
                                :rules="[rules.required]"
                            />
                        </v-col>
                        <v-col cols="12" xl="4" lg="4" md="4" v-if="false">
                            <v-select
                                v-model="invoice.type"
                                :items="types"
                                item-text="name"
                                item-value="id"
                                outline
                                prefix="Type: "
                                hide-details
                                prepend-icon="mdi-toggle-switch-off-outline"
                                :rules="[rules.required]"
                            />
                        </v-col>
                        <v-col cols="12" xl="4" lg="4" md="4">
                            <v-select
                                v-model="invoice.region"
                                :items="regions"
                                outline
                                prefix="Region: "
                                hide-details
                                prepend-icon="mdi-earth"
                            />
                        </v-col>
                        <v-col cols="12" xl="4" lg="4" md="4">
                            <v-text-field
                                hide-details
                                v-model="invoice.notes"
                                prepend-icon="mdi-text"
                                prefix="Notes: "
                                outline
                            />
                        </v-col>
                    </v-row>
                    <v-row ref="details_2">
                        <v-col cols="12">
                            <div class="d-flex my-2">
                                <div class="d-flex align-center">
                                    <strong>ATTACHED: </strong>
                                </div>
                                <div class="d-flex align-center flex-wrap">
                                    <v-btn
                                        color="grey lighten-3"
                                        elevation="0"
                                        x-small
                                        class="ma-1 text-capitalize grey--text text--darken-1  "
                                        v-for="(file, i) in invoice.attachments"
                                        :key="i"
                                    >
                                        <span @click="openFileAttached(file)">{{
                                            file
                                        }}</span>
                                        <v-icon
                                            v-if="
                                                invoice.status != 'billed' &&
                                                    invoice.status !=
                                                        'approved' &&
                                                    invoice.status != 'closed'
                                            "
                                            @click="deleteFile(file)"
                                            class="ml-1"
                                            small
                                        >
                                            mdi-close-circle
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        @click="onButtonClick"
                                        depressed
                                        fab
                                        x-small
                                        color="primary"
                                        class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                                        v-if="
                                            invoice.status != 'billed' &&
                                                invoice.status != 'approved' &&
                                                invoice.status != 'closed'
                                        "
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                    <input
                                        ref="uploader2"
                                        class="d-none"
                                        type="file"
                                        @change="onFileChanged"
                                    />
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
                <v-row v-if="validateConcept" no-gutters>
                    <v-col cols="12" ls="2" md="2" sm="2">
                        <div class="d-flex justity-center">
                            <v-text-field
                                type="number"
                                hide-details
                                v-model="invoice.amount"
                                prepend-icon="mdi-text"
                                prefix="Amount: "
                                outline
                            />
                        </div>
                    </v-col>
                </v-row>
                <v-row
                    v-if="!validateConcept"
                    no-gutters
                    :style="{ 'background-color': '#eeeeee' }"
                    class="mt-8"
                    ref="totals"
                >
                    <v-col>
                        <p class="my-2 ml-2 font-weight-black">TOTALS:</p>
                    </v-col>
                    <v-col>
                        <p v-if="project.price" class="my-2 font-weight-black">
                            Price: {{ numberFormat(project.price) }}
                        </p>
                    </v-col>
                    <v-col>
                        <p class="my-2 font-weight-black">
                            Billed:
                            {{ numberFormat(invoiceTotalBilled()) }}
                        </p>
                    </v-col>
                    <v-col>
                        <p class="my-2 font-weight-black">
                            Balance to Bill:
                            {{ numberFormat(project.price - invoiceBilled) }}
                        </p>
                    </v-col>
                    <v-col>
                        <p class="my-2 font-weight-black">
                            QTY: {{ invoiceTotalQtyBilled().toFixed(2) }}
                        </p>
                    </v-col>
                    <v-col>
                        <p class="my-2 font-weight-black">
                            Current Invoice:
                            {{ numberFormat(totalCurrentInvoce.toFixed(2)) }}
                        </p>
                    </v-col>
                </v-row>
                <v-row
                    v-if="!validateConcept"
                    no-gutters
                    class="quote_tables mt-8"
                >
                    <v-col
                        cols="12"
                        v-for="quoteId of Object.keys(invoice.items)"
                        :key="quoteId"
                        class="mb-6"
                    >
                        <v-data-table
                            :headers="headers"
                            :items="invoice.items[quoteId]"
                            class="elevation-0"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                            multiple-expand
                            :expanded.sync="invoice.items[quoteId]"
                        >
                            <template v-slot:top>
                                <v-row
                                    no-gutter
                                    :style="{
                                        'background-color': '#eeeeee',
                                    }"
                                    class="mt-0 mx-0"
                                >
                                    <v-col cols="12" class="d-flex">
                                        <h2 class="mt-1">
                                            QUOTE:
                                            {{ getQuoteName(quoteId) }}
                                        </h2>
                                    </v-col>
                                </v-row>
                            </template>
                            <!--ITEMS-->
                            <template v-slot:[`item.index`]="{ index }">
                                {{ index + 1 }}
                            </template>
                            <template v-slot:[`item.name`]="{ item }">
                                <p
                                    class="my-0 font-weight-bold text-capitalize"
                                >
                                    {{ item.name }}
                                </p>
                            </template>
                            <template v-slot:[`item.billedQty`]="{ item }">
                                <p class="my-0">
                                    {{ calculateBilledQty(item).toFixed(2) }}
                                </p>
                            </template>
                            <template v-slot:[`item.pricePerUnit`]="{ item }">
                                <p class="my-0">
                                    {{ numberFormat(item.pricePerUnit) }}
                                </p>
                            </template>
                            <template v-slot:[`item.totalPrice`]="{ item }">
                                <p class="my-0">
                                    {{
                                        numberFormat(
                                            item.qty * item.pricePerUnit
                                        )
                                    }}
                                </p>
                            </template>
                            <template v-slot:[`item.amountBilled`]="{ item }">
                                <p class="my-0">
                                    {{
                                        numberFormat(
                                            calculateBilledQty(item) *
                                                item.pricePerUnit
                                        )
                                    }}
                                </p>
                            </template>
                            <template v-slot:[`item.balanceToBill`]="{ item }">
                                <p class="my-0">
                                    {{
                                        numberFormat(
                                            item.qty * item.pricePerUnit -
                                                calculateBilledQty(item) *
                                                    item.pricePerUnit
                                        )
                                    }}
                                </p>
                            </template>
                            <template v-slot:[`item.category`]="{ item }">
                                <div class="d-flex justify-center ma-0 pa-0">
                                    <v-select
                                        v-model="item.category"
                                        :items="categories"
                                        item-text="name"
                                        outline
                                    />
                                </div>
                            </template>
                            <template
                                v-slot:[`item.invoiceQuantity`]="{ item }"
                            >
                                <div class="d-flex justify-center">
                                    <v-text-field
                                        type="number"
                                        v-model="item.invoiceQuantity"
                                        :style="{ 'max-width': '50px' }"
                                        @input="
                                            changeQuantity(
                                                item.invoiceQuantity,
                                                item
                                            )
                                        "
                                        :disabled="
                                            calculateBilledQty(item) == item.qty
                                        "
                                        :rules="[rules.number, maxValue(item)]"
                                    />
                                </div>
                            </template>
                            <template v-slot:[`item.currentInvoice`]="{ item }">
                                <p class="my-0">
                                    {{ numberFormat(item.currentInvoice || 0) }}
                                </p>
                            </template>
                            <!-- LOGS TABLE -->
                            <template
                                v-slot:expanded-item="{
                                    headers,
                                    item: item,
                                }"
                            >
                                <td
                                    v-if="item.logs && item.logs.length > 0"
                                    :colspan="headers.length"
                                    class="pa-4 ma-0 outlined"
                                    :style="{ background: 'white' }"
                                >
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <v-data-table
                                                :headers="logHeaders"
                                                :items="item.logs"
                                                class="elevation-0"
                                                :mobile-breakpoint="0"
                                                :loading="loading"
                                                disable-pagination
                                                hide-default-footer
                                            >
                                                <!--ITEMS-->
                                                <template
                                                    v-slot:[`item.billedOn`]="{
                                                        item,
                                                    }"
                                                >
                                                    <p
                                                        class="my-0"
                                                        v-if="item.billedOn"
                                                    >
                                                        {{
                                                            formatDate(
                                                                item.billedOn
                                                                    ._seconds
                                                            )
                                                        }}
                                                    </p>
                                                </template>
                                                <template
                                                    v-slot:[`item.createdOn`]="{
                                                        item,
                                                    }"
                                                >
                                                    <p
                                                        class="my-0"
                                                        v-if="item.createdOn"
                                                    >
                                                        {{
                                                            formatDate(
                                                                item.createdOn
                                                                    ._seconds
                                                            )
                                                        }}
                                                    </p>
                                                </template>
                                                <template
                                                    v-slot:[`item.qty`]="{
                                                        item,
                                                    }"
                                                >
                                                    <p class="my-0">
                                                        {{
                                                            calculateItemQty(
                                                                item
                                                            )
                                                        }}
                                                    </p>
                                                </template>
                                                <template
                                                    v-slot:[`item.amount`]="{
                                                        item,
                                                    }"
                                                >
                                                    <p class="my-0">
                                                        {{
                                                            numberFormat(
                                                                calculateItemQty(
                                                                    item
                                                                ) *
                                                                    item.pricePerUnit
                                                            )
                                                        }}
                                                    </p>
                                                </template>
                                            </v-data-table>
                                        </v-col>
                                    </v-row>
                                </td>
                            </template>
                            <!--FOOTER-->
                            <template v-slot:[`body.append`]>
                                <tr
                                    :style="{
                                        'background-color': '#eeeeee',
                                    }"
                                >
                                    <td class="font-weight-bold text-center">
                                        TOTALS:
                                    </td>
                                    <td
                                        class="font-weight-bold text-center"
                                    ></td>
                                    <td class="font-weight-bold">
                                        QTY:
                                        {{
                                            quoteTotalQty(
                                                invoice.items[quoteId]
                                            ).toFixed(2)
                                        }}
                                    </td>
                                    <td class="font-weight-bold">
                                        QTY BILLED:
                                        {{
                                            quoteTotalQtyBilled(
                                                invoice.items[quoteId]
                                            ).toFixed(2)
                                        }}
                                    </td>
                                    <td
                                        class="font-weight-bold text-center"
                                    ></td>
                                    <td class="font-weight-bold">
                                        PRICE:
                                        {{
                                            numberFormat(
                                                quoteTotalPrice(
                                                    invoice.items[quoteId]
                                                )
                                            )
                                        }}
                                    </td>
                                    <td class="font-weight-bold">
                                        AMOUNT BILLED:
                                        {{
                                            numberFormat(
                                                quoteAmountBilled(
                                                    invoice.items[quoteId]
                                                )
                                            )
                                        }}
                                    </td>
                                    <td class="font-weight-bold">
                                        BALANCE TO BILL:
                                        {{
                                            numberFormat(
                                                quoteBalanceToBill(
                                                    invoice.items[quoteId]
                                                )
                                            )
                                        }}
                                    </td>
                                    <td />
                                    <td class="font-weight-bold text-center">
                                        SELECTED QTY:
                                        {{
                                            quoteTotalInvoiceQty(
                                                invoice.items[quoteId]
                                            ).toFixed(2)
                                        }}
                                    </td>
                                    <td class="font-weight-bold text-center">
                                        CURRENT INVOICE:
                                        {{
                                            numberFormat(
                                                quoteTotalcurrentInvoice(
                                                    invoice.items[quoteId]
                                                )
                                            )
                                        }}
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions ref="actions">
                <v-spacer />
                <v-btn
                    v-if="invoice.region == 'international'"
                    rounded
                    color="#EDC43B"
                    class="mb-4 mr-2 white--text"
                    @click="openInvoiceDetails"
                    :disabled="!disableButtons"
                >
                    SEND TO INTERNATIONAL APPROVAL
                </v-btn>
                <v-btn
                    v-if="invoice.region == 'local'"
                    rounded
                    color="#EDC43B"
                    class="mb-4 mr-2 white--text"
                    @click="openInvoiceDetails"
                    :disabled="!disableButtons"
                >
                    SEND TO GENERATE INVOICE
                </v-btn>
            </v-card-actions>
            <!--Error Alert-->
            <Errors />
        </v-card>
        <!--INVOICE DETAILS-->
        <v-dialog
            fullscreen
            v-model="invoiceDetails"
            :retain-focus="false"
            persistent
        >
            <InvoiceDetails
                v-if="invoiceDetails"
                :invoice="invoice"
                :project="project"
                :inProcess="true"
                :setting="setting"
                :localAttachments="localAttachments"
                @close="closeInvoiceDetails"
            />
        </v-dialog>
        <!--Rejected-->
        <v-dialog persistent max-width="600px" v-model="rejectionTable">
            <RejectedInvoicesTable
                v-if="rejectionTable"
                :items="invoice.rejectionReasons"
                @closeDialog="rejectionTable = false"
            />
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import { deleteFile } from '@/services/storage/deleteFile.js'
import { openFile } from '@/services/storage/openFile.js'
import { saveFile } from '@/services/storage/saveFile.js'
import API from '@/services/api'

export default {
    name: 'InvoiceRequest',
    props: {
        project: {
            type: Object,
            required: true,
        },
        invoice: {
            type: Object,
            default: () => ({}),
        },
        setting: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        Errors: () => import('@/components/Layout/Errors'),
        InvoiceDetails: () =>
            import('@/components/Invoicing/InvoiceDetails.vue'),
        RejectedInvoicesTable: () =>
            import('@/components/Invoicing/RejectedInvoicesTable'),
    },
    data: () => ({
        valid: false,
        invoiceDetails: false,
        height: 0,
        loading: false,
        concepts: [],
        types: [],
        categories: [],
        regions: ['international', 'local'],
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                sortable: false,
                align: 'center',
            },
            {
                text: 'ITEM',
                value: 'name',
                sortable: false,
            },
            {
                text: 'TOTAL QTY',
                value: 'qty',
                sortable: false,
            },
            {
                text: 'QTY BILLED',
                value: 'billedQty',
                sortable: false,
            },
            {
                text: 'UNIT PRICE',
                value: 'pricePerUnit',
                sortable: false,
            },
            {
                text: 'TOTAL PRICE',
                value: 'totalPrice',
                sortable: false,
            },
            {
                text: 'AMOUNT BILLED',
                value: 'amountBilled',
                sortable: false,
            },
            {
                text: 'BALANCE TO BILL',
                value: 'balanceToBill',
                sortable: false,
            },
            {
                text: 'CATEGORY',
                value: 'category',
                sortable: false,
                align: 'center',
                width: '200px',
            },
            {
                text: 'QTY',
                value: 'invoiceQuantity',
                sortable: false,
                align: 'center',
            },
            {
                text: 'CURRENT INVOICE',
                value: 'currentInvoice',
                sortable: false,
                align: 'center',
            },
        ],
        logHeaders: [
            {
                text: 'Invoice ID',
                value: 'number',
                sortable: false,
            },
            {
                text: 'Status',
                value: 'status',
                sortable: false,
            },
            {
                text: 'Created By',
                value: 'createdBy',
                sortable: false,
            },
            {
                text: 'Creation Date',
                value: 'createdOn',
                sortable: false,
            },
            {
                text: 'Billed By',
                value: 'billedBy',
                sortable: false,
            },
            {
                text: 'Billing Date',
                value: 'billedOn',
                sortable: false,
            },
            {
                text: 'Qty',
                value: 'qty',
                sortable: false,
            },
            {
                text: 'Amount',
                value: 'amount',
                sortable: false,
            },
            {
                text: 'Notes',
                value: 'notes',
                sortable: false,
            },
        ],
        rules: {
            number: v => v > 0 || 'Not valid',
            required: v => !!v || 'The value is required',
        },
        validQuantity: false,
        validInvoice: false,
        invoiceBilled: 0,
        totalCurrentInvoce: 0,
        localAttachments: [],
        companyId: JSON.parse(localStorage.getItem('company')),
        selectedFile: null,
        title: null,
        rejectionTable: false,
    }),
    computed: {
        // validation in order to distinguish when a concept does
        // not use the amounts of the quotes for billing purposes
        validateConcept() {
            if (this.invoice.concept) {
                const concept = this.getConcept(this.invoice.concept)
                if (concept) {
                    return concept.enabledValue
                }
            }
            return false
        },
        disableButtons() {
            return (
                this.validateConcept ||
                (this.valid && this.validQuantity && this.validInvoice)
            )
        },
    },
    watch: {
        totalCurrentInvoce(newValue) {
            const b2b = this.invoice.project.price - this.invoiceTotalBilled()
            // it is allowed to maintain a margin of error around one unit
            if (newValue > b2b + 1) {
                this.validInvoice = false
            } else {
                this.validInvoice = true
            }
        },
    },
    async mounted() {
        try {
            this.completeProject = await API.getProject(this.project.id)
            this.loading = true
            this.title = this.invoice.number
                ? 'Invoice Request'
                : 'New Invoice Request'
            this.categories = this.setting.categories
            this.concepts = this.setting.concepts
            this.types = this.setting.types
            this.validQuantities()
            // set quotes
            if (
                this.invoice.project.quotes &&
                this.invoice.project.quotes.length > 0 &&
                typeof this.invoice.project.quotes[0] == 'string'
            ) {
                this.invoice.project.quotes = await API.getProjectQuotes(
                    this.invoice.project.id
                )
            }
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        getConcept(id) {
            return this.concepts.find(c => c.id == id)
        },
        async saveFile(file) {
            try {
                this.loading = true
                if (!this.invoice.status) {
                    this.localAttachments.push(file)
                    if (!this.invoice.attachments) {
                        this.invoice.attachments = [file.name]
                    } else {
                        this.invoice.attachments.push(file.name)
                    }
                    return null
                }
                await saveFile(
                    file,
                    `${this.companyId}/projects/${this.project.id}/invoices`
                )
                if (!this.invoice.attachments) {
                    this.invoice.attachments = [file.name]
                } else {
                    this.invoice.attachments.push(file.name)
                }
                await API.invoiceAttachments({
                    id: this.invoice.id,
                    projectId: this.invoice.projectId,
                    files: this.invoice.attachments,
                })
            } catch (error) {
                console.log(error)
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        onButtonClick() {
            this.$refs.uploader2.click()
        },
        async onFileChanged(e) {
            this.selectedFile = e.target.files[0]
            await this.saveFile(this.selectedFile)
        },
        async deleteFile(file) {
            try {
                this.loading = true
                if (!this.invoice.status) {
                    this.localAttachments = this.localAttachments.filter(
                        f => f != file
                    )
                    this.invoice.attachments = this.invoice.attachments.filter(
                        f => f != file
                    )
                    return null
                }
                await deleteFile(
                    file,
                    `${this.companyId}/projects/${this.project.id}/invoices`
                )
                this.invoice.attachments = this.invoice.attachments.filter(
                    f => f != file
                )
                await API.invoiceAttachments({
                    id: this.invoice.id,
                    projectId: this.invoice.projectId,
                    files: this.invoice.attachments,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async openFileAttached(file) {
            try {
                this.loading = true
                if (!this.invoice.status) {
                    const localFile = this.localAttachments.find(
                        f => f.name == file
                    )
                    if (localFile) {
                        const url = URL.createObjectURL(localFile)
                        window.open(url, '_blank')
                    }
                    return null
                }
                await openFile(
                    file,
                    `${this.companyId}/projects/${this.project.id}/invoices`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        invoiceTotalCurrentInvoce() {
            let total = 0
            for (const quoteId of Object.keys(this.invoice.items)) {
                for (const cost of this.invoice.items[quoteId]) {
                    if (cost.currentInvoice) {
                        total += cost.currentInvoice
                    }
                }
            }
            this.totalCurrentInvoce = total
        },
        invoiceTotalQtyBilled() {
            let total = 0
            for (const quoteId of Object.keys(this.invoice.items)) {
                for (const cost of this.invoice.items[quoteId]) {
                    if (cost.logs) {
                        const billedLogs = cost.logs.filter(
                            log => log.billedQty
                        )
                        if (billedLogs.length > 0) {
                            total += billedLogs.reduce(
                                (accumulator, cost) =>
                                    accumulator + cost.billedQty,
                                0
                            )
                        }
                    }
                }
            }
            return total
        },
        invoiceTotalBilled() {
            let total = 0
            for (const quoteId of Object.keys(this.invoice.items)) {
                for (const cost of this.invoice.items[quoteId]) {
                    const qty = this.billedItemQty(cost)
                    total += (qty || 0) * (cost.pricePerUnit || 0)
                }
            }
            this.invoiceBilled = total
            return total
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('L LT')
        },
        numberFormat(number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.invoice.currency,
                maximumFractionDigits: 2,
            }).format(number)
        },
        quoteTotalcurrentInvoice(costs) {
            return costs.reduce(
                (accumulator, cost) =>
                    accumulator +
                    (cost.invoiceQuantity ? Number(cost.invoiceQuantity) : 0) *
                        cost.pricePerUnit,
                0
            )
        },
        quoteTotalInvoiceQty(costs) {
            return costs.reduce(
                (accumulator, cost) =>
                    accumulator +
                    (cost.invoiceQuantity ? Number(cost.invoiceQuantity) : 0),
                0
            )
        },
        quoteBalanceToBill(costs) {
            return costs.reduce(
                (accumulator, cost) =>
                    accumulator +
                    (cost.qty * cost.pricePerUnit -
                        this.calculateBilledQty(cost) * cost.pricePerUnit),
                0
            )
        },
        quoteAmountBilled(costs) {
            return costs.reduce(
                (accumulator, cost) =>
                    accumulator +
                    this.calculateBilledQty(cost) * cost.pricePerUnit,
                0
            )
        },
        quoteTotalPrice(costs) {
            return costs.reduce(
                (accumulator, cost) =>
                    accumulator + cost.qty * cost.pricePerUnit,
                0
            )
        },
        quoteTotalQtyBilled(costs) {
            return costs.reduce(
                (accumulator, cost) =>
                    accumulator + this.calculateBilledQty(cost),
                0
            )
        },
        quoteTotalQty(costs) {
            return costs.reduce(
                (accumulator, cost) => accumulator + cost.qty,
                0
            )
        },
        validQuantities() {
            let validQuantity = false
            let editedFields = 0
            for (const costs of Object.values(this.invoice.items)) {
                validQuantity = true
                for (const cost of costs) {
                    editedFields += cost.invoiceQuantity ? 1 : 0
                    if (
                        cost.invoiceQuantity &&
                        parseFloat(cost.invoiceQuantity) > 0
                    ) {
                        const qty = parseFloat(this.availableItemQty(cost) || 0)
                        if (parseFloat(cost.invoiceQuantity) > qty) {
                            this.validQuantity = false
                            return
                        }
                    } else if (
                        cost.invoiceQuantity &&
                        parseFloat(cost.invoiceQuantity) < 1
                    ) {
                        this.validQuantity = false
                        return
                    }
                }
            }
            this.validQuantity = validQuantity && editedFields > 0
        },
        changeQuantity(quantity, item) {
            const qty = this.availableItemQty(item)
            if (item.invoiceQuantity > qty || item.invoiceQuantity == 0) {
                delete item.invoiceQuantity
                delete item.currentInvoice
                this.validQuantities()
                this.invoiceTotalCurrentInvoce()
                return null
            }
            item.currentInvoice = Number(quantity) * item.pricePerUnit
            this.validQuantities()
            this.invoiceTotalCurrentInvoce()
        },
        getQuoteName(quoteId) {
            const quote = this.invoice.project.quotes.find(
                quote => quote.id == quoteId
            )
            return quote.name
        },
        closeInvoiceDetails() {
            this.invoiceDetails = false
        },
        openInvoiceDetails() {
            if (!this.validateConcept && !this.validQuantity) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: 'You need to enter values for invoicing',
                })
                return null
            }
            if (!this.validateConcept) {
                for (const key of Object.keys(this.invoice.items)) {
                    for (const cost of this.invoice.items[key]) {
                        if (cost.invoiceQuantity) {
                            cost.invoiceQuantity = Number(cost.invoiceQuantity)
                            if (!cost.category) {
                                this.setErrorItems({
                                    source: this.$options.name,
                                    message:
                                        'You need to enter the category for the items with quantity to bill.',
                                })
                                return null
                            }
                        }
                    }
                }
            }
            this.invoiceDetails = true
        },
        onResize() {
            const {
                title: { clientHeight: titleHeight },
            } = this.$refs
            this.height = window.innerHeight - titleHeight - 100
        },
        close() {
            this.$emit('close')
        },
        maxValue(item) {
            const billedQty = this.calculateBilledQty(item)
            if (item.logs) {
                if (item.qty - billedQty == 0) {
                    return 'All quantities have already been invoiced'
                } else if (item.invoiceQuantity > item.qty - billedQty) {
                    return `The value cannot be greater than ${item.qty -
                        billedQty}.`
                } else {
                    return true
                }
            } else {
                if (item.invoiceQuantity > item.qty) {
                    return `The value cannot be greater than ${item.qty}.`
                } else {
                    return true
                }
            }
        },
        calculateBilledQty(item) {
            let itemsInCredit = 0
            if (this.completeProject && this.completeProject.invoices) {
                this.completeProject.invoices.forEach(invoice => {
                    if (invoice.creditNotes) {
                        invoice.creditNotes.forEach(creditNote => {
                            if (
                                creditNote.creditRequests &&
                                typeof creditNote.creditRequests == 'object'
                            ) {
                                for (const quoteId of Object.keys(
                                    creditNote.creditRequests
                                )) {
                                    creditNote.creditRequests[quoteId].forEach(
                                        cost => {
                                            if (cost.costId == item.costId) {
                                                itemsInCredit += cost.creditQty
                                            }
                                        }
                                    )
                                }
                            }
                        })
                    }
                })
            }
            return (item.billedQty || 0) - itemsInCredit
        },
        billedItemQty(item) {
            let qty = 0
            const logs = item.logs || []
            logs.forEach(invoice => {
                qty +=
                    (invoice.billedQty || invoice.invoiceQuantity || 0) -
                    (invoice.creditQty || 0)
            })
            return qty
        },
        availableItemQty(item) {
            let qty = 0
            const logs = item.logs || []
            logs.forEach(invoice => {
                qty +=
                    (invoice.billedQty || invoice.invoiceQuantity || 0) -
                    (invoice.creditQty || 0)
            })
            return item.qty - qty
        },
        calculateItemQty(item) {
            return (
                (item.billedQty ? item.billedQty : item.invoiceQty) -
                (item.creditQty || 0)
            )
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
.quote_tables {
    width: 100%;
}
</style>
